import React from 'react';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/Preview';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { IconButton, Theme, useTheme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      margin: theme.spacing(1),
      boxShadow: theme.shadows[5],
      borderRadius: '50%',
      padding: theme.spacing(1),
      color: theme.palette.primary.main,
      '&:hover, &.MuiIconButton': {
        backgroundColor: 'theme.palette.primary.main !important',
        color: 'white !important',
      },
    },
  })
);

const FanGoIconButton = ({
  type,
  onClick,
  size,
  disabled,
  backgroundColor = 'white',
  htmlColor = '',
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const renderIcon = () => {
    switch (type) {
      case 'edit':
        return <EditIcon fontSize={size} color={'primary'} />;
      case 'delete':
        return <DeleteIcon fontSize={size} color={'primary'} />;
      case 'cancel':
        return <CancelIcon fontSize={size} color={'primary'} />;
      case 'add':
        return (
          <AddCircleIcon
            fontSize={size}
            color={htmlColor ? '' : 'primary'}
            htmlColor={htmlColor}
          />
        );
      case 'reset':
        return <RotateLeftIcon fontSize={size} color={'primary'} />;
      case 'download':
        return <CloudDownloadIcon fontSize={size} color={'primary'} />;
      case 'preview':
        return <PreviewIcon fontSize={size} color={'primary'} />;
    }
  };

  return (
    <IconButton
      className={classes.iconButton}
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
      disabled={disabled}
      sx={{ backgroundColor: backgroundColor }}
    >
      {renderIcon()}
    </IconButton>
  );
};

export default FanGoIconButton;
